import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'

const Flickity =
  typeof window !== "undefined"
    ? require("react-flickity-component")
    : () => null

const fade =
  typeof window !== "undefined"
    ? require("flickity-fade")
    : () => null

const flickityOptions = {
    prevNextButtons: true,
    pageDots: false,
    cellSelector: '.slider__primary-slider-cell',
    wrapAround: false,
    setGallerySize: false,
    cellAlign: 'center',
    autoPlay: true,
    fade: true
}

function Slider(slides) {
    //console.log('slides: ', slides);
    console.log('fade: ', fade);
    return (
        <Flickity
            className={'slider slider__primary-slider'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate={true}
            static={true}
        >
            {slides.data.map((slide, i) => (
                <div key={`slide_` + i + ``} className="slider__primary-slider-cell">
                    {slide.link !== "" && (
                    <a href={slide.link} title={slide.link} rel="noopener">
                        <GatsbyImage image={slide.image.childImageSharp.gatsbyImageData} alt={`slide_` + i + ``} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "fit" }} />
                    </a>
                    )}
                    {slide.link === "" && (
                        <GatsbyImage image={slide.image.childImageSharp.gatsbyImageData} alt={`slide_` + i + ``} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "fit" }} />
                    )}
                </div>
            ))}
        </Flickity>
    )
}

const HomePage = ({data}) => {
    const entry = data.markdownRemark
    //console.log('entry: ', entry);
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname="/" />
            <Header />
            <div className="layout grey">
                <div className="slider__container">
                    <div className="slider__block">
                        <Slider data={entry.frontmatter.slides} />
                    </div>
                </div>
            </div>
            <main className="layout home grey">
                <div className="home__container">
                    <div className="home__flex flex__space-between">
                        <aside className="home__img">
                            <StaticImage src="../images/abt_img.png" alt="Kiwi as" style={{ display: "block" }} imgStyle={{ objectFit: "contain" }} />
                        </aside>
                        <article className="home__intro text">
                            <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                        </article>
                    </div>
                </div>
            </main>
            <div className="layout grey">
                <div className="ads__container">
                    <div className="ads__flex flex__space-around flex__align-items ad_gap">
                        <div className="ad">
                            <a href="https://www.accesssolutions.co.nz/find-solutions/" title="Find Solutions" rel="nofollow noopener">
                                <StaticImage src="../images/step-1.jpg" alt="Find solutions" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "contain" }} />
                            </a>
                        </div>
                        <div className="ad">
                            <Link to="/solutions/fair-wear-tear/">
                                <StaticImage src="../images/fair_wear_tear_home.jpg" alt="Fair Wear and Tear" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "contain" }} />  
                            </Link>
                        </div>
                        <div className="ad">
                            <Link to="/used-forklifts/">
                                <StaticImage src="../images/ex-lease.jpg" alt="Ex Lease" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "contain" }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout home grey">
                <div className="industry__container industry">
                    <h2>Forklift solutions for your industry</h2>
                    <div className="industry_grid">
                        <div className="industry_relative">
                            <Link to="/manufacturing/">
                            <StaticImage src="../images/forklifts_manufacturing.jpg" alt="Fair Wear and Tear" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                            <div className="industry_overlay industry_absolute" />
                            <div className="industry_absolute industry_center">
                                <h3>Manufacturing solutions</h3>
                            </div>
                            </Link>
                        </div>
                        <div className="industry_relative">
                            <Link to="/retail-warehouse/">
                            <StaticImage src="../images/forklifts_retail.jpg" alt="Fair Wear and Tear" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                            <div className="industry_overlay industry_absolute" />
                            <div className="industry_absolute industry_center">
                                <h3>Retail and warehouse solutions</h3>
                            </div>
                            </Link>
                        </div>
                        <div className="industry_relative">
                            <Link to="/transport-distribution/">
                            <StaticImage src="../images/forklifts_distribution.jpg" alt="Fair Wear and Tear" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                            <div className="industry_overlay industry_absolute" />
                            <div className="industry_absolute industry_center">
                                <h3>Transport &amp; Distribution Solutions</h3>
                            </div>
                            </Link>
                        </div>
                        <div className="industry_relative">
                            <Link to="/small-business/">
                            <StaticImage src="../images/forklifts_smallbusiness.jpg" alt="Fair Wear and Tear" style={{ width: '100%', height: '100%', display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                            <div className="industry_overlay industry_absolute" />
                            <div className="industry_absolute industry_center">
                                <h3>Small Business Solutions</h3>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				page_title
				meta_description
                slides {
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                width: 800, 
                                placeholder: NONE,
                                quality: 100,
                            )
                        }
                    }
                    link
                }
			}
			fields {
                slug
			}
			html
		}
	}
`
